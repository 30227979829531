import clsx from 'clsx';
import * as styles from './stepper.module.css';

const Stepper = ({
    active = 0,
    stepsArray = [],
}: {active: number; stepsArray: Record<string, string>[]}) => {
    const buttons = stepsArray.map((_, index) => (
        <div
            key={`timeline-btn-${index}`}
            className={clsx(
                styles.section,
                index < active && styles.finished,
            )}
        >
            <div className={styles.buttonWrapper}>
                <span className={styles.shadow}/>
                <button
                    className={clsx(
                        styles.button,
                        index === active && styles.active,
                        index < active && styles.finished,
                    )}
                />
            </div>
        </div>
    ));

    return (
        <div className={styles.container}>
            {buttons}
        </div>
    );
};

export default Stepper;
