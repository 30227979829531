import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import {Tooltip} from 'react-tooltip';
import type {DataItem} from '../../../interfaces/bulkImport';
import type {BulkAddMutationInput} from '../../../graphql-types';
import type {BulkImportAction} from '../../../helpers/reducers/bulkImportReducer';
import * as styles from './customCellComponent.module.css';

interface CustomCellProps {
    rowData: DataItem,
    columnData: {
        key: keyof BulkAddMutationInput,
    },
    dispatch: React.Dispatch<BulkImportAction>,
    rowIndex: number,
    columnIndex: number,
    active: boolean,
    focus: boolean,
}

const CustomCellComponent: React.FC<CustomCellProps> = ({
    rowData,
    columnData,
    dispatch,
    rowIndex,
    columnIndex,
    active,
    focus,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const cellId = `${rowIndex}-${columnIndex}`;

    const cellValue = rowData[columnData.key]?.value ?? '';

    const error = rowData[columnData.key]?.error ?? '';

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value;

        dispatch({
            type: 'UPDATE_CELL',
            rowIndex,
            columnKey: columnData.key,
            value: updatedValue,
        });
    };

    useEffect(() => {
        if (active && focus && inputRef.current) {
            inputRef.current.select();
        }
    }, [active, focus]);

    return (
        <div className={clsx(styles.wrapper, error && styles.error)}>
            <input
                data-tooltip-id={cellId}
                data-tooltip-position-strategy='fixed'
                ref={inputRef}
                className='dsg-input'
                value={cellValue}
                onChange={handleValueChange}
            />
            <Tooltip
                id={cellId}
                positionStrategy='fixed'
                variant='error'
                noArrow={true}
                opacity={1}
                place='bottom'
                hidden={!error}
                delayShow={100}
                style={{
                    zIndex: 9999,
                    fontWeight: 600,
                    padding: '.2rem 1.5rem',
                    backgroundColor: 'var(--color-error)',
                }}
            >
                {error}
            </Tooltip>
        </div>
    );
};

export default React.memo(CustomCellComponent);
