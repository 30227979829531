/* eslint-disable react-hooks/exhaustive-deps */
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
} from 'react';
import {useMutation} from '@apollo/client';
import {
    countErrors, updateDataWithErrors, parseErrors, transformToBackendFormat,
} from '../../../helpers/bulkImport';
import {AddMutationsDocument} from '../../../graphql-types';
import type {DataItem, SuccessResponse, RowError} from '../../../interfaces/bulkImport';
import type {BulkImportAction} from '../../../helpers/reducers/bulkImportReducer';
import {BACKEND_ERRORS} from '../../../configs/pages/videos/bulkImport';
import toastify from '../../../helpers/toast/toastify';
import * as styles from './bulkImportConfirm.module.css';

interface BulkImportConfirmProps {
    data: DataItem[],
    dispatch: React.Dispatch<BulkImportAction>,
    onErrors: (count: number) => void,
    itemsToCreate: SuccessResponse,
}

interface ConfirmRef {
    handleConfirm: () => void,
}

const BulkImportConfirm = forwardRef(({
    data,
    dispatch,
    onErrors,
    itemsToCreate,
}: BulkImportConfirmProps, ref: React.Ref<ConfirmRef>) => {
    const dataToSend = data.map(transformToBackendFormat);

    const {createdMutations, createdVideos} = itemsToCreate;

    const createdVideosText = createdVideos > 0 ? `${createdVideos} videos and ` : '';

    const [addMutations, {loading}] = useMutation(AddMutationsDocument, {
        onCompleted: () => {
            dispatch({type: 'SET_IS_CONFIRMED', payload: true});
        },
        onError: errorData => {
            if (errorData.message !== BACKEND_ERRORS.badInput) {
                toastify({text: errorData.message, type: 'error'});

                return;
            }

            const [errorObject] = errorData.graphQLErrors;

            const parsedErrors = parseErrors(errorObject?.extensions?.errors as RowError[]);

            const newData = updateDataWithErrors(data, parsedErrors);
            const errorCount = countErrors(data);

            dispatch({type: 'SET_DATA', payload: newData});
            onErrors(errorCount);
            dispatch({type: 'SET_ERRORS_CHECKED', payload: false});

            toastify({text: errorData.message, type: 'error'});

            dispatch({type: 'SET_STEP_INDEX', payload: 0});
        },
    });

    useImperativeHandle(ref, () => ({
        handleConfirm: () => {
            const dataToSendWithoutEmptyRows = dataToSend.filter(row => (!Object.values(row).every(cell => [
                '',
                undefined,
                null,
            ].includes(cell))));

            addMutations({
                variables: {
                    inputs: dataToSendWithoutEmptyRows,
                    validateOnly: false,
                },
            });
        },
    }));

    useEffect(() => {
        dispatch({type: 'SET_IS_LOADING', payload: loading});
    }, [loading]);

    return (
        <div className={styles.summaryWrapper}>
            <div className={styles.mainText}>
                {`You are about to create ${createdVideosText} ${createdMutations} mutations`}
            </div>
            <div className={styles.secondaryText}>This might take a while, so please don&apos;t leave immediately once you hit that button.</div>
            <div className={styles.note}>Are you sure you want to proceed?</div>
        </div>
    );
});

export default BulkImportConfirm;
