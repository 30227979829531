import type {
    BackendErrors,
    ColumnConfig,
    DataItem,
    TableHeaders,
} from '../../../interfaces/bulkImport';

const IMPORT_STEPS: Record<string, string>[] = [
    {
        title: 'Step 1',
    },
    {
        title: 'Step 2',
    },
    {
        title: 'Complete!',
    },
];

const TABLE_HEADERS: TableHeaders = {
    default: 'Paste data from your sheet',
    error: 'Checking data entered',
};

const COLUMN_CONFIG: ColumnConfig[] = [
    {key: 'mcbt', title: 'MCBT/AID', minWidth: 200},
    {key: 'themeName', title: 'Theme', minWidth: 200},
    {key: 'languageShortName', title: 'Language', minWidth: 110},
    {key: 'title', title: 'Title', minWidth: 400},
    {key: 'keywordsString', title: 'Tags', minWidth: 400},
    {key: 'description', title: 'Description', minWidth: 500},
    {key: 'url', title: 'URL', minWidth: 500},
    {key: 'sku', title: 'SKU', minWidth: 200},
    {key: 'dateString', title: 'Start Date', minWidth: 150},
    {key: 'versionString', title: 'Version', minWidth: 200},
    {key: 'typeName', title: 'Type', minWidth: 200},
    {key: 'uploadPolicyName', title: 'Upload Policy', minWidth: 200},
    {key: 'channelName', title: 'Channel', minWidth: 200},
    {key: 'customId', title: 'Custom Id', minWidth: 250},
    {key: 'audioFile', title: 'Audio File', minWidth: 250},
    {key: 'captionFile', title: 'Caption File', minWidth: 250},
    {key: 'categoryName', title: 'Category Name', minWidth: 250},
    {key: 'customThumbnail', title: 'Custom Thumbnail', minWidth: 250},
    {key: 'note', title: 'Note', minWidth: 400},
];

const BACKEND_ERRORS: BackendErrors = {
    badInput: 'Import validation failed',
    noRow: 'No rows to import',
};

const DEFAULT_ROW = (): DataItem => ({
    mcbt: {value: ''},
    themeName: {value: ''},
    languageShortName: {value: ''},
    title: {value: ''},
    keywordsString: {value: ''},
    description: {value: ''},
    url: {value: ''},
    sku: {value: ''},
    dateString: {value: ''},
    versionString: {value: ''},
    typeName: {value: ''},
    uploadPolicyName: {value: ''},
    channelName: {value: ''},
    customId: {value: ''},
    audioFile: {value: ''},
    captionFile: {value: ''},
    categoryName: {value: ''},
    customThumbnail: {value: ''},
    note: {value: ''},
});

const DEFAULT_ROWS_COUNT = 3;

export {
    IMPORT_STEPS,
    TABLE_HEADERS,
    COLUMN_CONFIG,
    BACKEND_ERRORS,
    DEFAULT_ROWS_COUNT,
    DEFAULT_ROW,
};
