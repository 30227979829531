import {DEFAULT_ROWS_COUNT} from '../../configs/pages/videos/bulkImport';
import {generateDefaultRows} from '../bulkImport';
import type {DataItem, Steps, SuccessResponse} from '../../interfaces/bulkImport';
import type {BulkAddMutationInput} from '../../graphql-types';

export const initialState = {
    data: generateDefaultRows(DEFAULT_ROWS_COUNT),
    stepIndex: 0 as Steps,
    errorCount: 0,
    errorsChecked: false,
    isConfirmed: false,
    isLoading: false,
    errorMessage: '',
    itemsToCreate: {createdVideos: 0, createdMutations: 0} as SuccessResponse,
};

type BulkImportState = typeof initialState;

export type BulkImportAction =
    | {type: 'SET_DATA'; payload: DataItem[]}
    | {type: 'SET_STEP_INDEX'; payload: Steps}
    | {type: 'SET_ERROR_COUNT'; payload: number}
    | {type: 'SET_ERRORS_CHECKED'; payload: boolean}
    | {type: 'SET_IS_CONFIRMED'; payload: boolean}
    | {type: 'SET_IS_LOADING'; payload: boolean}
    | {type: 'SET_ERROR_MESSAGE'; payload: string}
    | {type: 'SET_ITEMS_TO_CREATE'; payload: SuccessResponse}
    | {type: 'UPDATE_CELL'; rowIndex: number; columnKey: keyof BulkAddMutationInput; value: string};

export const bulkImportReducer = (state: BulkImportState, action: BulkImportAction): BulkImportState => {
    switch (action.type) {
        case 'SET_DATA':
            return {...state, data: action.payload};
        case 'SET_STEP_INDEX':
            return {...state, stepIndex: action.payload};
        case 'SET_ERROR_COUNT':
            return {...state, errorCount: action.payload};
        case 'SET_ERRORS_CHECKED':
            return {...state, errorsChecked: action.payload};
        case 'SET_IS_CONFIRMED':
            return {...state, isConfirmed: action.payload};
        case 'SET_IS_LOADING':
            return {...state, isLoading: action.payload};
        case 'SET_ERROR_MESSAGE':
            return {...state, errorMessage: action.payload};
        case 'SET_ITEMS_TO_CREATE':
            return {...state, itemsToCreate: action.payload};
        case 'UPDATE_CELL':
            return {
                ...state,
                data: state.data.map((row, index) => {
                    if (index !== action.rowIndex) {
                        return row;
                    }

                    return {
                        ...row,
                        [action.columnKey]: {value: action.value},
                    };
                }),
            };
        default:
            return state;
    }
};
