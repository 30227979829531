import type {
    ParsedErrors,
    CellError,
    RowError,
    DataItem,
    DataCell,
    Steps,
    ButtonTexts,
} from '../interfaces/bulkImport';
import type {BulkAddMutationInput} from '../graphql-types';

const parseErrors = (errorLog: RowError[]): ParsedErrors => {
    const errors: ParsedErrors = {};

    errorLog.forEach(rowError => {
        const rowIndex = rowError.extensions.index;

        rowError.extensions.errors.forEach((cellError: CellError) => {
            const colKey = cellError.extensions.input as keyof BulkAddMutationInput;

            if (!errors[rowIndex]) {
                errors[rowIndex] = {};
            }

            errors[rowIndex][colKey] = cellError.message;
        });
    });

    return errors;
};

const countErrors = (data: DataItem[]) => data.reduce((count, row) => {
    const errorsInRow = Object.values(row).filter(cell => cell.error).length;

    return count + errorsInRow;
}, 0);

const updateDataWithErrors = (data: DataItem[], parsedErrors: ParsedErrors): DataItem[] => data.map((row, rowIndex) => {
    const updatedRow: DataItem = {...row};

    const errorsForCurrentRow = parsedErrors[rowIndex];

    if (parsedErrors[rowIndex]) {
        Object.keys(parsedErrors[rowIndex]).forEach(key => {
            const colKey = key as keyof DataItem;

            if (updatedRow && updatedRow[colKey]) {
                (updatedRow[colKey] as DataCell).error = errorsForCurrentRow[colKey];
            }
        });
    } else {
        Object.keys(updatedRow).forEach(key => {
            const colKey = key as keyof DataItem;

            if (updatedRow[colKey] && 'error' in (updatedRow[colKey] as DataCell)) {
                delete (updatedRow[colKey] as DataCell).error;
            }
        });
    }

    return updatedRow;
});

const transformToBackendFormat = (item: Record<string, DataCell>): BulkAddMutationInput => {
    const transformed: Partial<BulkAddMutationInput> = {};

    Object.entries(item).forEach(([key, value]) => {
        transformed[key as keyof BulkAddMutationInput] = value?.value;
    });

    return transformed as BulkAddMutationInput;
};

const generateDefaultRows = (count: number): DataItem[] => Array(count).fill({value: ''});

const returnButtonText = (step: Steps) => {
    const texts: ButtonTexts = {
        0: 'Continue',
        1: 'Yes, create all',
        2: 'Go back to the list of videos',
    };

    return texts[step];
};

export {
    parseErrors,
    updateDataWithErrors,
    transformToBackendFormat,
    generateDefaultRows,
    countErrors,
    returnButtonText,
};
