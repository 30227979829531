import {navigate} from 'gatsby';
import {
    useEffect,
    useReducer,
    useRef,
} from 'react';
import clsx from 'clsx';
import Layout from '../../components/layout/layout';
import Stepper from '../../components/core/stepper/stepper';
import {Button, Tabs} from '../../components/core';
import BulkImportDatasheet from '../../components/core/bulkImportDatasheet/bulkImportDatasheet';
import {returnButtonText} from '../../helpers/bulkImport';
import {TABLE_HEADERS, IMPORT_STEPS} from '../../configs/pages/videos/bulkImport';
import {Steps} from '../../interfaces/bulkImport';
import BulkImportConfirm from '../../components/core/bulkImportDatasheet/bulkImportConfirm';
import {bulkImportReducer, initialState} from '../../helpers/reducers/bulkImportReducer';
import * as styles from './bulk-import.module.css';

interface DatasheetRef {
    triggerMutation: () => void,
}

interface ConfirmRef {
    handleConfirm: () => void,
}

const BulkImportPage = () => {
    const datasheetRef = useRef<DatasheetRef>(null);
    const confirmRef = useRef<ConfirmRef>(null);

    const [state, dispatch] = useReducer(bulkImportReducer, initialState);

    const {
        data,
        stepIndex,
        errorCount,
        errorsChecked,
        isConfirmed,
        isLoading,
        errorMessage,
        itemsToCreate,
    } = state;

    const timelineSteps = IMPORT_STEPS.filter((_, index) => index !== IMPORT_STEPS.length - 1);

    const createdVideosText = itemsToCreate.createdVideos > 0 ? `${itemsToCreate.createdVideos} videos and ` : '';

    const handleErrors = (count: number) => {
        dispatch({type: 'SET_ERROR_COUNT', payload: count});
    };

    const stepBack = () => {
        if (stepIndex < 1) {
            dispatch({type: 'SET_STEP_INDEX', payload: 0});

            return;
        }

        dispatch({type: 'SET_STEP_INDEX', payload: stepIndex - 1 as Steps});
        dispatch({type: 'SET_ERRORS_CHECKED', payload: false});
    };

    const stepForward = () => {
        if (stepIndex === 0 && !errorsChecked && datasheetRef.current) {
            datasheetRef.current.triggerMutation();

            return;
        }

        if (stepIndex === 1 && confirmRef.current) {
            confirmRef.current.handleConfirm();

            return;
        }

        if (stepIndex === IMPORT_STEPS.length - 1) {
            navigate('/videos');
        }
    };

    useEffect(() => {
        if (errorsChecked) {
            dispatch({type: 'SET_STEP_INDEX', payload: 1});
        }

        if (isConfirmed) {
            dispatch({type: 'SET_STEP_INDEX', payload: 2});
        }
    }, [errorsChecked, isConfirmed]);

    useEffect(() => {
        if (errorCount) {
            dispatch({type: 'SET_ERROR_MESSAGE', payload: `${errorCount} ${errorCount === 1 ? 'problem' : 'problems'} found, please check and correct the data`});
        }
    }, [errorCount]);

    return (
        <Layout
            title="Bulk import"
            scope="videos.write"
        >
            <Tabs
                data={[]}
            />
            <Stepper active={stepIndex} stepsArray={timelineSteps}/>
            <h2 className={clsx(styles.stepIndicator, stepIndex > 0 && styles.centered)}>
                {IMPORT_STEPS[stepIndex].title}
            </h2>
            {stepIndex === 0 && (
                <>
                    <div className={styles.statusContainer}>
                        <span className={styles.statusText}>{!errorCount ? TABLE_HEADERS.default : TABLE_HEADERS.error}</span>
                        <span className={styles.errorCount}>{!!errorCount && errorMessage}</span>
                    </div>
                    <div className={styles.datasheet}>
                        <BulkImportDatasheet
                            ref={datasheetRef}
                            data={data}
                            dispatch={dispatch}
                            onErrors={handleErrors}
                        />
                    </div>
                </>
            )}
            {stepIndex === 1 && (
                <BulkImportConfirm
                    ref={confirmRef}
                    data={data}
                    dispatch={dispatch}
                    onErrors={handleErrors}
                    itemsToCreate={itemsToCreate}
                />
            )}
            {stepIndex === 2 && (
                <div className={styles.summaryWrapper}>
                    <div className={styles.mainText}>You&apos;ve created {createdVideosText}{itemsToCreate.createdMutations} mutations</div>
                    <div className={styles.secondaryText}>Way to go! Thanks for waiting. Feel free to run anywhere else you need now.</div>
                    <div className={clsx(styles.note, styles.success)}>All done.</div>
                </div>
            )}
            <div className={styles.buttonsWrapper}>
                {stepIndex > 0 && stepIndex < 2 && (
                    <Button
                        text="Back"
                        onClick={stepBack}
                        variant="outline"
                        size="medium"
                    />
                )}
                <Button
                    text={returnButtonText(stepIndex)}
                    onClick={stepForward}
                    size="medium"
                    loading={isLoading}
                />
            </div>
        </Layout>
    );
};

export default BulkImportPage;
